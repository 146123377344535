import { ReactNode } from 'react'
import Sidebar from './Sidebar'
import ChatList from './ChatList'
import ChatContent from './ChatContent'

interface Props {
  chat?: ReactNode
}

export const Teams = ({ chat }: Props) => {
  return (
    <div className="flex h-screen text-sm font-sans">
      <Sidebar />
      <ChatList />
      <ChatContent chat={chat} />
    </div>
  )
}
