import { ReactNode, useRef, useEffect } from 'react'

interface Props {
  messages?: ReactNode
  inputChat?: ReactNode
  isMessages?: boolean
}

import styles from './ChatFull.module.css'

import svgLogo from '../../assets/svg/logo-ligia.svg'

export const ChatContainer = ({ messages, inputChat, isMessages }: Props) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className={styles.chat}>
      <div className="absolute w-full h-full flex justify-center items-center">
        <div
          className={`flex flex-col gap-1 items-center transition-all ease-in-out delay-150 ${isMessages ? 'opacity-40' : ''}`}>
          <img src={svgLogo} alt="logo" className={`${styles.chatLogo} ${isMessages ? 'grayscale' : ''}`} />
          <div className={styles.chatLogoSlogan}>Inteligência que simplifica o RH</div>
        </div>
      </div>
      <div className={`flex flex-col ${styles.chatContainer}`}>
        <div className="flex-1 overflow-y-auto">
          {messages}
          <div ref={chatEndRef} />
        </div>
        <div className="flex-none">{inputChat}</div>
      </div>
    </div>
  )
}
