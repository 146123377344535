import avatar from '../../assets/img/avatar.png'
import logoLigia from '../../assets/svg/logo-ligia.svg'

interface Props {
  image?: string
  isLigia?: boolean
  customClass?: string
}

export const Avatar = ({ isLigia, customClass, image }: Props) => {
  return (
    <div>
      {isLigia ? (
        <div className={`${customClass ? customClass : 'w-8 h-8'} rounded-full border border-slate-300 p-1`}>
          <img src={logoLigia} alt="logo" className="w-full h-full" />
        </div>
      ) : (
        <img src={image || avatar} alt="avatar" className={`${customClass ? customClass : 'w-8 h-8'} rounded-full`} />
      )}
    </div>
  )
}
