import { useNavigate } from 'react-router-dom'
import { ReactNode } from 'react'

interface Props {
  icon: ReactNode
  indicator: string
  title: string
  description: string
  route?: string
}

import styles from './CardShadow.module.css'

export const CardShadow = ({ icon, indicator, title, description, route }: Props) => {
  const navigate = useNavigate()
  const handleCardClick = () => {
    if (route) {
      navigate(route)
    }
  }

  return (
    <div
      onClick={handleCardClick}
      className="relative w-full h-full bg-gradient-to-b from-white to-transperent rounded-3xl shadow-r-lg hover:shadow-blue-300 cursor-pointer group">
      <div className="absolute z-0 -top-16 left-1/2 -translate-x-1/2 w-28 h-28 bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500 content-[''] before:absolute before:z-1 before:w-full before:h-full before:rounded-full before:bg-gradient-to-r before:from-blue-500 before:via-transparent before:to-blue-500 before:rotate-90 rounded-full flex items-center justify-center text-white text-lg font-bold">
        <span className="relative -top-3 skew-x-3 drop-shadow-md">{indicator}</span>
      </div>
      <div
        className={`bg-white/10 backdrop-blur-md rounded-3xl shadow-lg group-hover:shadow-blue-300 p-6 relative flex flex-col gap-2 h-full ${styles.custom_border}`}>
        <div className="">
          <div className="w-20 h-20 bg-white shadow-lg shadow-blue-500 -rotate-45 rounded-full mx-auto flex items-center justify-center text-blue-600 text-2xl">
            <span className="rotate-45">{icon}</span>
          </div>
        </div>

        <h3 className="text-blue-600 font-bold text-center">{title}</h3>
        <p className="flex-1 text-gray-500 text-xs mb-4">{description}</p>
        <div className="flex justify-end text-blue-500 text-xl">
          <svg viewBox="0 0 24 24" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <g>
                <g>
                  <g>
                    <polyline
                      fill="none"
                      points="16.4 7 21.5 12 16.4 17"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <line
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      x1="2.5"
                      x2="19.2"
                      y1="12"
                      y2="12"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  )
}
