import React, { useState, useEffect, useRef } from 'react'

interface Props {
  onMessageChange: (data: { message: string; files?: FileList }) => void
  onClearChat: () => void
  placeholder?: string
  isLoading: boolean
}

import styles from './ChatPro.module.css'

import { LoadingAn } from './LoadingAn'
import { ChatUpload } from './ChatUpload'

export const ChatInput = ({
  onMessageChange,
  onClearChat,
  placeholder = 'Escreva aqui sua pergunta',
  isLoading = false
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus()
    }
  }, [isLoading])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }
  const sendMessageToParent = () => {
    if (!inputValue) return
    onMessageChange({
      message: inputValue
    })
    setInputValue('')
  }
  const onUpload = (files: FileList) => {
    if ((!files || !files.length) && !inputValue) return
    onMessageChange({
      message: inputValue,
      files: files || []
    })
    setInputValue('')
  }
  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
      ev.preventDefault()
      sendMessageToParent()
    }
  }

  return (
    <div className="w-full relative">
      <div className="absolute bottom-2 left-1">
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="w-5 h-5 text-primary-lg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"></path>
          </g>
        </svg>
      </div>
      <input
        className={`w-full h-10 ${styles.chatInputText}`}
        type="text"
        ref={inputRef}
        placeholder={placeholder}
        disabled={isLoading}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={onEnterPress}
      />
      <div className="absolute bottom-1 right-1 flex gap-2">
        <button
          className={`self-center flex-none ${styles.chatButton} ${isLoading ? styles.buttonAnimate : ''}`}
          disabled={isLoading}
          onClick={onClearChat}
          onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClearChat() : null)}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 1024 1024">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path d="M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-0.3 1.5-0.4 3-0.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-0.1 4.4-0.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390z m468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z"></path>
            </g>
          </svg>
        </button>
        {/* <ChatUpload loadingSendMessage={isLoading} onUpload={onUpload} /> */}
        {isLoading && (
          <div className="self-center flex-none p-2">
            <LoadingAn />
          </div>
        )}
        {!isLoading && (
          <button
            className={`self-center flex-none ${styles.chatButton} ${isLoading ? styles.buttonAnimate : ''}`}
            disabled={isLoading}
            onClick={sendMessageToParent}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendMessageToParent() : null)}>
            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
              <g strokeWidth={0}></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M10.3009 13.6949L20.102 3.89742M10.5795 14.1355L12.8019 18.5804C13.339 19.6545 13.6075 20.1916 13.9458 20.3356C14.2394 20.4606 14.575 20.4379 14.8492 20.2747C15.1651 20.0866 15.3591 19.5183 15.7472 18.3818L19.9463 6.08434C20.2845 5.09409 20.4535 4.59896 20.3378 4.27142C20.2371 3.98648 20.013 3.76234 19.7281 3.66167C19.4005 3.54595 18.9054 3.71502 17.9151 4.05315L5.61763 8.2523C4.48114 8.64037 3.91289 8.83441 3.72478 9.15032C3.56153 9.42447 3.53891 9.76007 3.66389 10.0536C3.80791 10.3919 4.34498 10.6605 5.41912 11.1975L9.86397 13.42C10.041 13.5085 10.1295 13.5527 10.2061 13.6118C10.2742 13.6643 10.3352 13.7253 10.3876 13.7933C10.4468 13.87 10.491 13.9585 10.5795 14.1355Z"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>
              </g>
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}
