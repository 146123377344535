export default function ChatInput() {
  return (
    <div className="relative p-4 border-t flex items-center space-x-2">
      <input
        type="text"
        placeholder="Digite uma mensagem"
        className="flex-1 border rounded pl-3 pr-32 py-2 text-sm focus:outline-none focus:ring focus:border-blue-400"
      />
      <div className="absolute right-6 flex space-x-2">
        <button>
          <svg viewBox="0 0 24 24" className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="currentColor" fillRule="nonzero">
                  <path d="M20.0624471,8.44531708 C21.3187966,9.70246962 21.3181456,11.7400656 20.060993,12.9964151 L12.9378099,20.1075964 C12.6748336,20.3701297 12.3509291,20.5634444 11.9950254,20.6702751 L7.7020125,21.9588974 C7.17304403,22.1176765 6.61551448,21.8175787 6.4567354,21.2886102 C6.39849549,21.0945854 6.40055641,20.8874478 6.4626453,20.6946202 L7.82404878,16.466551 C7.93368607,16.1260538 8.12292759,15.8166043 8.37607629,15.5638705 L15.5089127,8.44272265 C16.767237,7.18646041 18.8055552,7.18762176 20.0624471,8.44531708 Z M16.5687028,9.50425223 L9.43586633,16.6254001 C9.35148343,16.7096447 9.28840293,16.8127945 9.25185716,16.9262936 L8.18207614,20.2486798 L11.563783,19.2336018 C11.6824175,19.1979916 11.7903857,19.1335534 11.8780445,19.0460423 L19.0000352,11.936052 C19.6718508,11.2646659 19.6721983,10.1768173 19.0014481,9.50563832 C18.3299482,8.83370918 17.2409679,8.83308872 16.5687028,9.50425223 Z M8.1508398,2.36975012 L8.20132289,2.47486675 L11.4537996,10.724 L10.2967996,11.879 L9.5557996,10 L5.4427996,10 L4.44747776,12.5208817 C4.30788849,12.8739875 3.9301318,13.0620782 3.57143476,12.9736808 L3.47427411,12.9426336 C3.1211683,12.8030443 2.93307758,12.4252876 3.02147501,12.0665906 L3.05252224,11.9694299 L6.80613337,2.47427411 C7.0415216,1.87883471 7.84863764,1.84414583 8.1508398,2.36975012 Z M7.50274363,4.79226402 L6.0357996,8.5 L8.9637996,8.5 L7.50274363,4.79226402 Z" />
                </g>
              </g>
            </g>
          </svg>
        </button>
        <button>
          <svg viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
              <path
                d="M9 16C9.85038 16.6303 10.8846 17 12 17C13.1154 17 14.1496 16.6303 15 16"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5Z"
                fill="currentColor"
              />
              <ellipse cx="9" cy="10.5" rx="1" ry="1.5" fill="currentColor" />
            </g>
          </svg>
        </button>
        <button>
          <svg viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M4 12H20M12 4V20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </button>
        <div className="w-[1px] h-5 bg-gray-500"></div>
        <button>
          <svg viewBox="0 0 24 24" className="w-5 h-5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  )
}
