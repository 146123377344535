import ChatItem from './ChatItem'
import avatar80 from '../../assets/img/80.jpg'
import avatar81 from '../../assets/img/81.jpg'
import avatar82 from '../../assets/img/82.jpg'

export default function ChatList() {
  const pinned = [
    { name: 'Antônio Silva Melo (Você)', preview: '', date: '23/01', status: 'online' },
    { name: 'Ligia Pro', preview: '', date: 'Hoje', isLigia: true, active: true, status: 'online' }
  ]

  const recent = [
    {
      image: avatar80,
      name: 'João Vitor',
      preview: 'Você: blz',
      date: '13:07',
      status: 'chamada'
    },
    {
      image: avatar81,
      name: 'Marcos Vinicio',
      preview: 'Você: do dashboard',
      date: '13:07',
      status: 'online'
    },
    {
      image: avatar82,
      name: 'Roberta Souza',
      preview: 'Você: eu estou bem, bom dia 😊',
      date: '09:08',
      status: 'offline'
    }
  ]

  return (
    <div className="w-80 bg-[#f6f2ed] border-r flex flex-col">
      <div className="px-4 py-3 font-semibold text-lg border-b">Chat</div>

      <div className="overflow-y-auto flex-1 px-3 py-2 space-y-4">
        <div>
          <h4 className="text-gray-500 text-xs uppercase mb-1">Fixado</h4>
          {pinned.map((chat, i) => (
            <ChatItem key={i} {...chat} />
          ))}
        </div>

        <div>
          <h4 className="text-gray-500 text-xs uppercase mb-1">Recentes</h4>
          {recent.map((chat, i) => (
            <ChatItem key={i} {...chat} />
          ))}
        </div>
      </div>
    </div>
  )
}
