export default function Sidebar() {
  const icons = ['💬', '📅', '📣', '👥', '📌', '📞', '☁️', '📦']

  return (
    <div className="w-20 bg-[#ebebeb] border-r border-[#e0e0e0] flex flex-col items-center py-4 space-y-4">
      <div className="flex flex-col justify-center items-center gap-0">
        <svg viewBox="0 0 512 512" className="w-8 h-8" fill="#939393" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <g>
              {' '}
              <g>
                {' '}
                <path d="m256,127.8c19.8,0 35.4-16.7 35.4-35.4 0-19.8-15.6-35.4-35.4-35.4s-35.4,15.6-35.4,35.4c2.84217e-14,19.7 15.6,35.4 35.4,35.4zm0-52.2c9.4,0 15.6,7.3 15.6,15.6 0,8.3-7.3,15.6-15.6,15.6s-15.6-6.3-15.6-15.6c0-8.3 6.2-15.6 15.6-15.6z"></path>{' '}
                <path d="m112.1,325.9c-19.8,0-35.4,15.6-35.4,35.4s15.6,35.4 35.4,35.4c18.8,0 35.4-15.6 35.4-35.4s-15.6-35.4-35.4-35.4zm0,50c-8.3,0-15.6-7.3-15.6-15.6s6.3-15.6 15.6-15.6c8.3,0 15.6,7.3 15.6,15.6s-6.2,15.6-15.6,15.6z"></path>{' '}
                <path d="m399.9,289.4c-33,0-62,15.4-80.4,39.5l-54.1-49.3v-57.3c51-4.8 91.7-48.6 91.7-100.7 0-56.3-44.8-101.1-101.1-101.1s-101.1,45.8-101.1,101c0,53.1 40.8,96 91.7,100.7v56.7l-54.3,49.6c-18.4-23.9-47.4-39.2-80.2-39.2-56.3,0.1-101.1,44.9-101.1,101.2s45.9,101.1 101.1,101.1 101.1-44.8 101.1-101.1c0-16.1-3.7-31.3-10.2-44.7l52.6-48.4 53.3,48.6c-6.5,13.4-10.1,28.5-10.1,44.5 0,56.3 45.9,101.1 101.1,101.1 56.3,0 101.1-44.8 101.1-101.1s-44.8-101.1-101.1-101.1zm-333.3,168.4c5-15.8 19.3-26.7 36.2-26.7h18.8c16.8,0 31.1,10.9 36.2,26.7-13,8.8-28.7,14-45.5,14-17,0-32.7-5.2-45.7-14zm106.6-13.7c-9.9-18.8-29.5-31.8-51.6-31.8h-18.8c-23,0-42.7,12.3-52.1,31.2-12.3-14.3-19.8-32.8-19.8-53.1 0-44.8 36.5-81.3 81.3-81.3 43.8,0 81.3,36.5 81.3,81.3-0.1,20.6-7.7,39.4-20.3,53.7zm82.8-241.3c-16.9,0-32.5-5.2-45.5-14 5-15.8 19.3-26.7 36.2-26.7h18.8c16.8,0 31.1,10.9 36.2,26.7-13.2,8.9-28.8,14-45.7,14zm-81.3-81.3c0-44.8 36.5-81.3 81.3-81.3s81.3,36.5 81.3,81.3c0,20.5-7.7,39.3-20.3,53.6-9.9-18.8-29.5-31.8-51.6-31.8h-18.8c-23,0-42.7,12.3-52.1,31.2-12.3-14.1-19.8-32.7-19.8-53zm179.6,336.3c5-15.8 19.3-26.7 36.2-26.7h18.8c16.8,0 31.1,10.9 36.2,26.7-13,8.8-28.7,14-45.5,14s-32.7-5.2-45.7-14zm106.6-13.7c-9.9-18.8-29.5-31.8-51.6-31.8h-18.8c-23,0-42.7,12.3-52.1,31.2-12.3-14.3-19.8-32.8-19.8-53.1 0-44.8 36.5-81.3 81.3-81.3s81.3,36.5 81.3,81.3c5.68434e-14,20.6-7.7,39.4-20.3,53.7z"></path>{' '}
                <path d="m399.9,325.9c-19.8,0-35.4,15.6-35.4,35.4s15.6,35.4 35.4,35.4 35.4-15.6 35.4-35.4-15.6-35.4-35.4-35.4zm0,50c-8.3,0-15.6-7.3-15.6-15.6s6.3-15.6 15.6-15.6 15.6,7.3 15.6,15.6-6.2,15.6-15.6,15.6z"></path>{' '}
              </g>{' '}
            </g>{' '}
          </g>
        </svg>
        <div className="text-[10px] text-[#939393]">Entre a Gente</div>
      </div>
      <div className="flex flex-col justify-center items-center gap-0">
        <svg viewBox="0 0 24 24" className="w-8 h-8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <path
              opacity="0.5"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88836 21.6244 10.4003 22 12 22Z"
              fill="#5b5fc7"></path>{' '}
            <path
              d="M7.825 12.85C7.36937 12.85 7 13.2194 7 13.675C7 14.1306 7.36937 14.5 7.825 14.5H13.875C14.3306 14.5 14.7 14.1306 14.7 13.675C14.7 13.2194 14.3306 12.85 13.875 12.85H7.825Z"
              fill="#5b5fc7"></path>{' '}
            <path
              d="M7.825 9C7.36937 9 7 9.36937 7 9.825C7 10.2806 7.36937 10.65 7.825 10.65H16.625C17.0806 10.65 17.45 10.2806 17.45 9.825C17.45 9.36937 17.0806 9 16.625 9H7.825Z"
              fill="#5b5fc7"></path>{' '}
          </g>
        </svg>
        <div className="text-[10px] text-[#5b5fc7]">Chat</div>
      </div>
      <div className="flex flex-col justify-center items-center gap-0">
        <svg viewBox="0 0 24 24" className="w-8 h-8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <path
              d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
              stroke="#939393"
              strokeWidth="1.5"></path>{' '}
            <path d="M7 4V2.5" stroke="#939393" strokeWidth="1.5" strokeLinecap="round"></path>{' '}
            <path d="M17 4V2.5" stroke="#939393" strokeWidth="1.5" strokeLinecap="round"></path>{' '}
            <path d="M2.5 9H21.5" stroke="#939393" strokeWidth="1.5" strokeLinecap="round"></path>{' '}
            <path
              d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z"
              fill="#939393"></path>{' '}
            <path
              d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z"
              fill="#939393"></path>{' '}
            <path
              d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
              fill="#939393"></path>{' '}
            <path
              d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z"
              fill="#939393"></path>{' '}
            <path
              d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z"
              fill="#939393"></path>{' '}
            <path
              d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z"
              fill="#939393"></path>{' '}
          </g>
        </svg>
        <div className="text-[10px] text-[#939393]">Calendário</div>
      </div>
      <div className="flex flex-col justify-center items-center gap-0">
        <svg viewBox="0 0 24 24" className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" fill="#939393">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <title></title>{' '}
            <g id="Complete">
              {' '}
              <g id="bell">
                {' '}
                <g>
                  {' '}
                  <path
                    d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z"
                    fill="none"
                    stroke="#939393"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"></path>{' '}
                  <path
                    d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1"
                    fill="none"
                    stroke="#939393"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"></path>{' '}
                </g>{' '}
              </g>{' '}
            </g>{' '}
          </g>
        </svg>
        <div className="text-[10px] text-[#939393]">Atividade</div>
      </div>
      <div className="flex flex-col justify-center items-center gap-0">
        <svg viewBox="0 0 24 24" className="w-8 h-8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <circle cx="12" cy="8" r="3" stroke="#939393" strokeWidth="2" strokeLinecap="round"></circle>{' '}
            <path
              d="M15.2679 8C15.5332 7.54063 15.97 7.20543 16.4824 7.06815C16.9947 6.93086 17.5406 7.00273 18 7.26795C18.4594 7.53317 18.7946 7.97 18.9319 8.48236C19.0691 8.99472 18.9973 9.54063 18.7321 10C18.4668 10.4594 18.03 10.7946 17.5176 10.9319C17.0053 11.0691 16.4594 10.9973 16 10.7321C15.5406 10.4668 15.2054 10.03 15.0681 9.51764C14.9309 9.00528 15.0027 8.45937 15.2679 8L15.2679 8Z"
              stroke="#939393"
              strokeWidth="2"></path>{' '}
            <path
              d="M5.26795 8C5.53317 7.54063 5.97 7.20543 6.48236 7.06815C6.99472 6.93086 7.54063 7.00273 8 7.26795C8.45937 7.53317 8.79457 7.97 8.93185 8.48236C9.06914 8.99472 8.99727 9.54063 8.73205 10C8.46683 10.4594 8.03 10.7946 7.51764 10.9319C7.00528 11.0691 6.45937 10.9973 6 10.7321C5.54063 10.4668 5.20543 10.03 5.06815 9.51764C4.93086 9.00528 5.00273 8.45937 5.26795 8L5.26795 8Z"
              stroke="#939393"
              strokeWidth="2"></path>{' '}
            <path
              d="M16.8816 18L15.9013 18.1974L16.0629 19H16.8816V18ZM20.7202 16.9042L21.6627 16.5699L20.7202 16.9042ZM14.7808 14.7105L14.176 13.9142L13.0194 14.7927L14.2527 15.5597L14.7808 14.7105ZM19.8672 17H16.8816V19H19.8672V17ZM19.7777 17.2384C19.7707 17.2186 19.7642 17.181 19.7725 17.1354C19.7804 17.0921 19.7982 17.0593 19.8151 17.0383C19.8474 16.9982 19.874 17 19.8672 17V19C21.0132 19 22.1414 17.9194 21.6627 16.5699L19.7777 17.2384ZM17 15C18.6416 15 19.4027 16.1811 19.7777 17.2384L21.6627 16.5699C21.1976 15.2588 19.9485 13 17 13V15ZM15.3857 15.5069C15.7702 15.2148 16.282 15 17 15V13C15.8381 13 14.9028 13.3622 14.176 13.9142L15.3857 15.5069ZM14.2527 15.5597C15.2918 16.206 15.7271 17.3324 15.9013 18.1974L17.8619 17.8026C17.644 16.7204 17.0374 14.9364 15.309 13.8614L14.2527 15.5597Z"
              fill="#939393"></path>{' '}
            <path
              d="M9.21918 14.7105L9.7473 15.5597L10.9806 14.7927L9.82403 13.9142L9.21918 14.7105ZM3.2798 16.9041L4.22227 17.2384L4.22227 17.2384L3.2798 16.9041ZM7.11835 18V19H7.93703L8.09867 18.1974L7.11835 18ZM7.00001 15C7.71803 15 8.22986 15.2148 8.61433 15.5069L9.82403 13.9142C9.09723 13.3621 8.1619 13 7.00001 13V15ZM4.22227 17.2384C4.59732 16.1811 5.35842 15 7.00001 15V13C4.0515 13 2.80238 15.2587 2.33733 16.5699L4.22227 17.2384ZM4.13278 17C4.126 17 4.15264 16.9982 4.18486 17.0383C4.20176 17.0593 4.21961 17.0921 4.22748 17.1354C4.2358 17.181 4.22931 17.2186 4.22227 17.2384L2.33733 16.5699C1.85864 17.9194 2.98677 19 4.13278 19V17ZM7.11835 17H4.13278V19H7.11835V17ZM8.09867 18.1974C8.27289 17.3324 8.70814 16.206 9.7473 15.5597L8.69106 13.8614C6.96257 14.9363 6.356 16.7203 6.13804 17.8026L8.09867 18.1974Z"
              fill="#939393"></path>{' '}
            <path
              d="M12 14C15.5715 14 16.5919 16.5512 16.8834 18.0089C16.9917 18.5504 16.5523 19 16 19H8C7.44772 19 7.00829 18.5504 7.11659 18.0089C7.4081 16.5512 8.42846 14 12 14Z"
              stroke="#939393"
              strokeWidth="2"
              strokeLinecap="round"></path>{' '}
          </g>
        </svg>
        <div className="text-[10px] text-[#939393]">Equipes</div>
      </div>
    </div>
  )
}
