import { ReactNode, useRef, useEffect } from 'react'

interface Props {
  messages?: ReactNode
  inputChat?: ReactNode
  isMessages?: boolean
}

import styles from './ChatTeams.module.css'

import svgLogo from '../../assets/svg/logo-ligia.svg'

export const ChatContainer = ({ messages, inputChat, isMessages }: Props) => {
  const chatEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className={styles.chat}>
      <div
        className={`absolute w-full h-full flex justify-center items-center transition-all ease-in-out delay-150 ${isMessages ? 'opacity-0' : ''}`}>
        <div className="flex-1 flex flex-col items-center justify-center text-center px-8">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-64 mb-4" fill="none" viewBox="0 0 512 512">
            <path
              fill="url(#a)"
              d="M236.709 402.502c92.508 0 167.5-74.992 167.5-167.5 0-92.507-74.992-167.5-167.5-167.5s-167.5 74.993-167.5 167.5c0 41.669 15.215 79.784 40.393 109.092a5.086 5.086 0 0 1 1.171 4.158l-8.282 48.88c-1.366 8.06 6.991 14.264 14.311 10.625l43.305-21.527a5.087 5.087 0 0 1 4.457-.018c21.848 10.443 46.314 16.29 72.145 16.29Z"
            />
            <path
              fill="url(#b)"
              fillOpacity=".4"
              d="M236.709 402.502c92.508 0 167.5-74.992 167.5-167.5 0-92.507-74.992-167.5-167.5-167.5s-167.5 74.993-167.5 167.5c0 41.669 15.215 79.784 40.393 109.092a5.086 5.086 0 0 1 1.171 4.158l-8.282 48.88c-1.366 8.06 6.991 14.264 14.311 10.625l43.305-21.527a5.087 5.087 0 0 1 4.457-.018c21.848 10.443 46.314 16.29 72.145 16.29Z"
            />
            <path
              fill="url(#c)"
              d="M236.709 402.502c92.508 0 167.5-74.992 167.5-167.5 0-92.507-74.992-167.5-167.5-167.5s-167.5 74.993-167.5 167.5c0 41.669 15.215 79.784 40.393 109.092a5.086 5.086 0 0 1 1.171 4.158l-8.282 48.88c-1.366 8.06 6.991 14.264 14.311 10.625l43.305-21.527a5.087 5.087 0 0 1 4.457-.018c21.848 10.443 46.314 16.29 72.145 16.29Z"
            />
            <path
              fill="url(#d)"
              d="M412.552 403.164a5.117 5.117 0 0 1 1.135-4.31c17.792-20.999 28.522-48.172 28.522-77.852 0-66.55-53.95-120.5-120.5-120.5s-120.5 53.95-120.5 120.5c0 66.551 53.95 120.5 120.5 120.5 18.495 0 36.016-4.166 51.677-11.612a5.12 5.12 0 0 1 4.375-.032l31.752 14.922c5.202 2.445 10.971-2.076 9.84-7.712l-6.801-33.904Z"
            />
            <path
              fill="url(#e)"
              d="M412.552 403.164a5.117 5.117 0 0 1 1.135-4.31c17.792-20.999 28.522-48.172 28.522-77.852 0-66.55-53.95-120.5-120.5-120.5s-120.5 53.95-120.5 120.5c0 66.551 53.95 120.5 120.5 120.5 18.495 0 36.016-4.166 51.677-11.612a5.12 5.12 0 0 1 4.375-.032l31.752 14.922c5.202 2.445 10.971-2.076 9.84-7.712l-6.801-33.904Z"
            />
            <path
              fill="url(#f)"
              d="M412.552 403.164a5.117 5.117 0 0 1 1.135-4.31c17.792-20.999 28.522-48.172 28.522-77.852 0-66.55-53.95-120.5-120.5-120.5s-120.5 53.95-120.5 120.5c0 66.551 53.95 120.5 120.5 120.5 18.495 0 36.016-4.166 51.677-11.612a5.12 5.12 0 0 1 4.375-.032l31.752 14.922c5.202 2.445 10.971-2.076 9.84-7.712l-6.801-33.904Z"
            />
            <path
              fill="url(#g)"
              fillOpacity=".4"
              d="M412.552 403.164a5.117 5.117 0 0 1 1.135-4.31c17.792-20.999 28.522-48.172 28.522-77.852 0-66.55-53.95-120.5-120.5-120.5s-120.5 53.95-120.5 120.5c0 66.551 53.95 120.5 120.5 120.5 18.495 0 36.016-4.166 51.677-11.612a5.12 5.12 0 0 1 4.375-.032l31.752 14.922c5.202 2.445 10.971-2.076 9.84-7.712l-6.801-33.904Z"
            />
            <path
              fill="url(#h)"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <path
              fill="url(#i)"
              fillOpacity=".8"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <path
              fill="url(#j)"
              fillOpacity=".85"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <path
              fill="url(#k)"
              fillOpacity=".85"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <path
              fill="url(#l)"
              fillOpacity=".85"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <path
              fill="url(#m)"
              fillOpacity=".85"
              d="M404.209 235.002c0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839Z"
            />
            <g fill="#AC76FF" clipPath="url(#n)">
              <path d="M333.6 223.029c0 .86-.671 1.57-1.505 1.57-.834 0-1.495-.7-1.495-1.57 0-.87.671-1.57 1.495-1.57.824 0 1.505.7 1.505 1.57ZM350.699 246.109a1 1 0 1 0 .002-1.998 1 1 0 0 0-.002 1.998ZM360.579 261.899c0 .84-.675 1.51-1.5 1.51a1.51 1.51 0 0 1 0-3.02c.825 0 1.5.68 1.5 1.51ZM310.289 251.819c.834 0 1.5-.68 1.5-1.51s-.675-1.51-1.5-1.51a1.51 1.51 0 0 0 0 3.02ZM226.249 357.489c0 .84-.675 1.51-1.5 1.51a1.51 1.51 0 0 1 0-3.02c.825 0 1.5.68 1.5 1.51ZM246.97 259.569a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM293.149 366.849c0 .554-.445 1-1 1-.554 0-1-.446-1-1s.446-1 1-1c.555 0 1 .446 1 1ZM283.369 365.849c.826 0 1.5-.62 1.5-1.38 0-.76-.674-1.38-1.5-1.38s-1.5.62-1.5 1.38c0 .76.674 1.38 1.5 1.38ZM252.839 373.979c0 .549-.451 1-1 1-.55 0-1-.451-1-1 0-.55.45-1 1-1 .549 0 1 .45 1 1ZM249.299 380.029c0 .84-.676 1.51-1.5 1.51a1.51 1.51 0 0 1 0-3.02c.824 0 1.5.68 1.5 1.51ZM279.129 241.169c.834 0 1.5-.68 1.5-1.51s-.676-1.51-1.5-1.51a1.51 1.51 0 0 0 0 3.02ZM288.229 244.119a1 1 0 1 0 .002-1.998 1 1 0 0 0-.002 1.998ZM245.209 354.969a1 1 0 1 0 .002-1.998 1 1 0 0 0-.002 1.998ZM236.859 363.259c0 .554-.445 1-1 1-.554 0-1-.446-1-1s.446-1 1-1c.555 0 1 .446 1 1ZM373.14 238.709a1.5 1.5 0 1 0-.001-3.001 1.5 1.5 0 0 0 .001 3.001Z" />
            </g>
            <path
              fill="#F2F1F3"
              fillRule="evenodd"
              d="M236.709 398.502c90.299 0 163.5-73.201 163.5-163.5v-.082c-20.711-18.898-48.256-30.418-78.5-30.418-64.341 0-116.5 52.159-116.5 116.5 0 29.755 11.149 56.896 29.505 77.489.664.007 1.329.011 1.995.011Zm-3.798 3.958c-19.686-21.448-31.702-50.05-31.702-81.458 0-66.55 53.95-120.5 120.5-120.5 31.915 0 60.931 12.407 82.49 32.661.007.613.01 1.226.01 1.839 0 92.508-74.992 167.5-167.5 167.5-1.269 0-2.535-.014-3.798-.042Z"
              clipRule="evenodd"
            />
            <circle cx="298.941" cy="300.796" r="22.666" fill="url(#o)" />
            <circle cx="298.941" cy="300.796" r="22.666" fill="url(#p)" fillOpacity=".5" />
            <circle cx="298.941" cy="300.796" r="22.666" fill="url(#q)" fillOpacity=".8" />
            <circle cx="248.691" cy="300.796" r="22.666" fill="url(#r)" />
            <circle cx="248.691" cy="300.796" r="22.666" fill="url(#s)" fillOpacity=".5" />
            <circle cx="248.691" cy="300.796" r="22.666" fill="url(#t)" fillOpacity=".8" />
            <circle cx="347.941" cy="300.796" r="22.666" fill="url(#u)" />
            <circle cx="347.941" cy="300.796" r="22.666" fill="url(#v)" fillOpacity=".5" />
            <circle cx="347.941" cy="300.796" r="22.666" fill="url(#w)" fillOpacity=".8" />
            <defs>
              <radialGradient
                id="a"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-57.973 526.317 260.997) scale(554.379 544.113)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".181" stopColor="#C5B4E3" />
                <stop offset=".654" stopColor="#E7E7E7" />
              </radialGradient>
              <radialGradient
                id="b"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(-33.00017 102.99964 -101.09215 -32.38903 280 328)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".834" stopColor="#9D9AA2" />
                <stop offset="1" stopColor="#DCD6E6" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="c"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-44.369 531.085 4.29) scale(324.737 239.179)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".826" stopColor="#E7E7E7" stopOpacity="0" />
                <stop offset=".95" stopColor="#D0D0D0" />
                <stop offset=".983" stopColor="#D0D0D0" />
              </radialGradient>
              <radialGradient
                id="d"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-57.911 591.34 126.443) scale(398.134 391.171)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".094" stopColor="#C5B4E3" />
                <stop offset=".599" stopColor="#E7E7E7" />
              </radialGradient>
              <radialGradient
                id="e"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-44.301 644.314 -107.415) scale(233.343 171.855)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".826" stopColor="#E7E7E7" stopOpacity="0" />
                <stop offset=".95" stopColor="#D0D0D0" />
                <stop offset=".983" stopColor="#D0D0D0" />
              </radialGradient>
              <radialGradient
                id="f"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(137.424 129.715 295.571) scale(100.491 197.096)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".177" stopColor="#D0D0D0" />
                <stop offset=".261" stopColor="#E7E4EC" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="g"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(147.00026 -10.00003 9.33822 137.27174 265.5 295.5)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".834" stopColor="#9D9AA2" />
                <stop offset="1" stopColor="#DCD6E6" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="h"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-32.533 494.93 -418.463) scale(266.753 358.854)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".065" stopColor="#ECCDBD" />
                <stop offset=".597" stopColor="#FFF1E4" />
              </radialGradient>
              <radialGradient
                id="i"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(118.936 32.131 260.892) scale(281.091 171.961)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".37" stopColor="#FFF1E4" stopOpacity="0" />
                <stop offset=".543" stopColor="#B9A69A" />
              </radialGradient>
              <radialGradient
                id="j"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(-28.99977 36.0002 -17.15923 -13.82253 342.5 315.5)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".605" stopColor="#B9A69A" />
                <stop offset="1" stopColor="#FBE9DB" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="k"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(-16.0003 39.74988 -25.10666 -10.10605 291.75 318.5)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".645" stopColor="#B9A69A" />
                <stop offset="1" stopColor="#FDEDDF" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="l"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(1.75 34.99998 -22.20546 1.11027 245.25 310)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".742" stopColor="#B9A69A" />
                <stop offset="1" stopColor="#FEEEE1" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="m"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(92.739 58.93 247.536) scale(131.648 200.357)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".64" stopColor="#CAB6A9" />
                <stop offset="1" stopColor="#FEEEE1" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="o"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(108.435 46.957 254.482) scale(41.6687)"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#BCF2FF" />
                <stop offset=".25" stopColor="#4ECBFF" />
                <stop offset=".645" stopColor="#0083F5" />
                <stop offset="1" stopColor="#C955FF" />
              </radialGradient>
              <radialGradient
                id="p"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(0 -16.4132 32.4355 0 298.16 323.462)"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#DEB3FF" />
                <stop offset="1" stopColor="#C9F" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="q"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(97.815 18.305 280.754) scale(40.2342)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".446" stopColor="#fff" stopOpacity="0" />
                <stop offset=".841" stopColor="#fff" />
              </radialGradient>
              <radialGradient
                id="r"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(108.435 21.832 236.373) scale(41.6687)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".068" stopColor="#FFC56D" />
                <stop offset=".25" stopColor="#FF9B04" />
                <stop offset=".645" stopColor="#E66000" />
                <stop offset="1" stopColor="#CBFBB5" />
              </radialGradient>
              <radialGradient
                id="s"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(0 -16.4132 32.4355 0 247.91 323.462)"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#CBFBB5" />
                <stop offset="1" stopColor="#CBFBB5" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="t"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(97.815 -6.82 258.842) scale(40.2342)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".446" stopColor="#fff" stopOpacity="0" />
                <stop offset=".841" stopColor="#fff" />
              </radialGradient>
              <radialGradient
                id="u"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(108.435 71.457 272.14) scale(41.6687)"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#F1FFB7" />
                <stop offset=".25" stopColor="#B9DC4E" />
                <stop offset=".645" stopColor="#6FB12F" />
                <stop offset="1" stopColor="#FF7C03" />
              </radialGradient>
              <radialGradient
                id="v"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(0 -16.4132 32.4355 0 347.16 323.462)"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#FFAB5D" />
                <stop offset="1" stopColor="#FFC499" stopOpacity="0" />
              </radialGradient>
              <radialGradient
                id="w"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(97.815 42.805 302.121) scale(40.2342)"
                gradientUnits="userSpaceOnUse">
                <stop offset=".446" stopColor="#fff" stopOpacity="0" />
                <stop offset=".841" stopColor="#fff" />
              </radialGradient>
              <clipPath id="n">
                <path fill="#fff" d="M223.239 221.459h151.4v160.09h-151.4z" />
              </clipPath>
            </defs>
          </svg>
          <div className="font-semibold text-lg">Você está iniciando uma nova conversa</div>
          <p className="text-gray-500 mt-2 text-sm leading-5">Digite a primeira mensagem abaixo.</p>
        </div>
      </div>
      <div className={`flex flex-col ${styles.chatContainer}`}>
        <div className="flex-1 overflow-y-auto">
          {messages}
          <div ref={chatEndRef} />
        </div>
        <div className="flex-none">{inputChat}</div>
      </div>
    </div>
  )
}
