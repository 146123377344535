import type { Citation, ExecResults, ChatMessage } from '../../api/models'

import { useState } from 'react'
import * as _ from 'lodash-es'

import { Answer } from '../Answer'

interface Props {
  onSend: (question: string, id?: string) => void
  onClearChat: () => void
  onShowCitation: (data: Citation) => void
  onShowExecResult: () => void
  onParseCitationFromMessage: (data: ChatMessage) => Citation[]
  onParsePlotFromMessage: (data: ChatMessage) => string | null
  execResults: ExecResults[]
  isLoading: boolean
  placeholder?: string
  conversationId?: string
  messages: ChatMessage[]
}

import styles from './ChatFull.module.css'

import { ChatInput } from './ChatInput'
import { ChatContainer } from './ChatContainer'
import { ChatMsg } from './ChatMsg'
import { Loading } from '../Loading'
export const ChatFull = ({
  onSend,
  onClearChat,
  onShowCitation,
  onShowExecResult,
  onParseCitationFromMessage,
  onParsePlotFromMessage,
  execResults,
  isLoading,
  placeholder,
  conversationId,
  messages
}: Props) => {
  const [files, setFiles] = useState<FileList>()

  const onMessageChange = ({ message, files }: { message: string; files?: FileList }) => {
    if (message.trim()) {
      if (conversationId) {
        onSend(message, conversationId)
      } else {
        onSend(message)
      }
    }

    if (files) {
      setFiles(files)
    }
  }

  return (
    <div className={styles.demo}>
      <ChatContainer
        isMessages={messages.length > 0}
        messages={
          <div className="flex flex-col gap-3 p-2">
            {_.map(
              messages,
              (msg, index) =>
                !_.isEmpty(msg) && (
                  <ChatMsg
                    key={index}
                    message={typeof msg.content === 'string' ? msg.content : msg.content[0].text}
                    role={msg.role}
                    messageTemplate={
                      typeof msg.content === 'string' && (
                        <Answer
                          noDisclaimer={true}
                          answer={{
                            answer:
                              `${msg.content}`.toLowerCase().search('the requested information') !== -1
                                ? 'Desculpe, não consegui encontrar a informação que você pediu. Talvez eu possa ajudar de outra forma! Se quiser reformular a pergunta, estou à disposição.'
                                : msg.content,
                            citations: onParseCitationFromMessage(messages[index - 1]),
                            generated_chart: onParsePlotFromMessage(messages[index - 1]),
                            message_id: msg.id,
                            feedback: msg.feedback,
                            exec_results: execResults
                          }}
                          onCitationClicked={c => onShowCitation(c)}
                          onExectResultClicked={() => onShowExecResult()}
                        />
                      )
                    }
                  />
                )
            )}
            {files && <ChatMsg role="user" message="" files={files} />}
            {isLoading && (
              <div className="p-2">
                <Loading />
              </div>
            )}
          </div>
        }
        inputChat={
          <ChatInput
            onMessageChange={onMessageChange}
            onClearChat={onClearChat}
            placeholder={placeholder}
            isLoading={isLoading}
          />
        }
      />
    </div>
  )
}
