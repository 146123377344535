import React, { useState, useEffect, useRef } from 'react'

interface Props {
  onMessageChange: (data: { message: string; files?: FileList }) => void
  onClearChat: () => void
  placeholder?: string
  isLoading: boolean
}

import styles from './ChatTeams.module.css'

import { LoadingAn } from './LoadingAn'
import { ChatUpload } from './ChatUpload'

export const ChatInput = ({
  onMessageChange,
  onClearChat,
  placeholder = 'Escreva aqui sua pergunta',
  isLoading = false
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus()
    }
  }, [isLoading])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }
  const sendMessageToParent = () => {
    if (!inputValue) return
    onMessageChange({
      message: inputValue
    })
    setInputValue('')
  }
  const onUpload = (files: FileList) => {
    if ((!files || !files.length) && !inputValue) return
    onMessageChange({
      message: inputValue,
      files: files || []
    })
    setInputValue('')
  }
  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey && !(ev.nativeEvent?.isComposing === true)) {
      ev.preventDefault()
      sendMessageToParent()
    }
  }

  return (
    <div className="w-full relative border rounded">
      <input
        className="relative w-full h-10 border-b-[3px] border-transparent rounded pl-3 pr-32 py-2 text-sm focus:outline-none focus:border-[#5b5fc7]"
        type="text"
        ref={inputRef}
        placeholder={placeholder}
        disabled={isLoading}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={onEnterPress}
      />
      <div className="absolute bottom-0 right-1 h-full flex items-center gap-2">
        <button
          className={`self-center flex-none group ${styles.chatButton} ${isLoading ? styles.buttonAnimate : ''}`}
          disabled={isLoading}
          onClick={onClearChat}
          onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? onClearChat() : null)}>
          <svg className="w-6 h-6 group-hover:hidden" fill="currentColor" viewBox="0 0 1024 1024">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path d="M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-0.3 1.5-0.4 3-0.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-0.1 4.4-0.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390z m468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z" />
            </g>
          </svg>
          <svg className="w-6 h-6 hidden group-hover:block" fill="currentColor" viewBox="0 0 1024 1024">
            <circle cx="172.64349" cy="369.009" r="0.66346425" />
            <circle cx="462.57916" cy="154.19305" r="0.66346425" />
            <circle cx="602.27539" cy="351.87646" r="0.66346425" />
            <path
              id="path2"
              d="M 432 112 C 417.60004 112 406 123.60004 406 138 L 406 320 L 160 320 C 145.60004 320 134 331.60004 134 346 L 134 492.89062 L 890 492.89062 L 890 346 C 890 331.60004 878.39996 320 864 320 L 618 320 L 618 138 C 618 123.60004 606.39996 112 592 112 L 432 112 z M 176.86133 569.98633 L 124.90039 869.59961 C 124.60039 871.09961 124.5 872.6 124.5 874 C 124.5 888.39996 136.10004 900 150.5 900 L 351.92578 900 L 351.92578 674.08789 C 351.92578 669.6879 355.5258 666.08789 359.92578 666.08789 L 407.92578 666.08789 C 412.32578 666.08789 415.92578 669.6879 415.92578 674.08789 L 415.92578 900 L 607.16602 900 L 607.16602 674.08789 C 607.16602 669.68791 610.76603 666.08789 615.16602 666.08789 L 663.16602 666.08789 C 667.566 666.08789 671.16602 669.68791 671.16602 674.08789 L 671.16602 900 L 873.5 900 C 875 900 876.50039 899.89961 877.90039 899.59961 C 892.10035 897.19961 901.59961 883.69957 899.09961 869.59961 L 847.13867 569.98633 L 176.86133 569.98633 z "
            />
          </svg>
        </button>
        {/* <ChatUpload loadingSendMessage={isLoading} onUpload={onUpload} /> */}
        <div className="self-center w-[1px] h-5 bg-gray-500"></div>
        {isLoading && (
          <div className="self-center flex-none p-2">
            <LoadingAn />
          </div>
        )}
        {!isLoading && (
          <button
            className={`self-center flex-none group ${styles.chatButton} ${isLoading ? styles.buttonAnimate : ''}`}
            disabled={isLoading}
            onClick={sendMessageToParent}
            onKeyDown={e => (e.key === 'Enter' || e.key === ' ' ? sendMessageToParent() : null)}>
            <svg
              viewBox="0 0 24 24"
              className="w-6 h-6 group-hover:hidden"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path
                  d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 hidden group-hover:block"
              fill="currentColor">
              <g strokeWidth="0"></g>
              <g strokeLinecap="round" strokeLinejoin="round"></g>
              <g>
                <path d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path>
              </g>
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}
