import styles from './ChatFull.module.css'

export const LoadingAn = () => {
  return (
    <div className={styles.loadingAI}>
      <div className={styles.loadingAIColorCircle}></div>
      <div className={styles.loadingAIColorCircleShadow}></div>
      <div className={styles.loadingAIInnerCircle}></div>
    </div>
  )
}
