import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { initializeIcons } from '@fluentui/react'

import LandingPage from './pages/landingPage/landingPage'
import LigiaPro from './pages/ligiaPro/LigiaPro'
import LigiaMobile from './pages/ligiaMobile/LigiaMobile'
import LigiaTeams from './pages/ligiaTeams/LigiaTeams'
import LayoutLigia from './pages/layout/LayoutLigia'
import LayoutMockCelular from './pages/layout/LayoutMockCelular'
import NoPage from './pages/NoPage'
import { AppStateProvider } from './state/AppProvider'

import './assets/css/tailwind.css'
import './index.css'

initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/')

export default function App() {
  return (
    <AppStateProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<LayoutLigia />}>
            <Route index element={<LandingPage />} />
            <Route path="autoatendimento" element={<LigiaPro />} />
            <Route path="mobile/:noBack?" element={<LigiaMobile />} />
            <Route path="teams" element={<LigiaTeams />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/mock-mobile" element={<LayoutMockCelular />} />
        </Routes>
      </HashRouter>
    </AppStateProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
