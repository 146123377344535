type Props = {
  name: string
  preview: string
  date: string
  active?: boolean
  isLigia?: boolean
  image?: string
  status?: string
}

import { Avatar } from '../Avatar'

export default function ChatItem({ name, preview, date, active, isLigia, image, status }: Props) {
  return (
    <div
      className={`flex gap-2 p-2 rounded border border-[#f6f2ed] cursor-pointer ${active ? 'bg-[#fcfbf9]' : 'hover:border hover:bg-[#fcfbf9]/70'}`}>
      <div className="relative">
        <Avatar isLigia={isLigia} image={image} />
        {status === 'online' && (
          <svg
            viewBox="0 0 24 24"
            className="absolute -bottom-0.5 right-0 w-3 h-3 bg-white text-green-500 rounded-full"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <rect width="24" height="24" fill="white"></rect>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.0243 14.3899C11.4586 14.9556 10.5414 14.9556 9.97568 14.3899L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                fill="currentColor"
              />
            </g>
          </svg>
        )}
        {status === 'offline' && (
          <svg
            className="absolute -bottom-0.5 right-0 w-3 h-3 bg-white text-gray-500 rounded-full"
            fill="currentColor"
            viewBox="0 0 96 96"
            xmlns="http://www.w3.org/2000/svg">
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <g>
                <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
                <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z" />
              </g>
            </g>
          </svg>
        )}
        {status === 'chamada' && (
          <div className="absolute -bottom-0.5 right-0 w-3 h-3 bg-red-500 rounded-full border border-white"></div>
        )}
      </div>
      <div className="self-center flex-1">
        <div className="font-medium text-sm flex justify-between">
          <span>{name}</span>
          <span className="text-xs text-gray-400">{date}</span>
        </div>
        <div className="text-xs text-gray-500 truncate">{preview}</div>
      </div>
    </div>
  )
}
