export const MockBarraLateral = () => {
  return (
    <div className="w-64 h-full bg-white p-4 border-r text-sm space-y-4">
      <button className="flex items-center text-primary-lg font-semibold space-x-2 hover:underline">
        <svg viewBox="0 0 24 24" className="w-4 h-4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M9 12H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 9L12 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"
              stroke="currentColor"
              strokeWidth="2"
            />
          </g>
        </svg>
        <span>Nova conversa</span>
      </button>

      <hr className="my-2" />

      <div>
        <div className="font-semibold text-gray-800 mb-1">Hoje</div>
        <ul className="space-y-1 pl-2">
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Marcação de férias
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div className="font-semibold text-gray-800 mb-1">Ontem</div>
        <ul className="space-y-1 pl-2">
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Ponto
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div className="font-semibold text-gray-800 mb-1">7 dias anteriores</div>
        <ul className="space-y-1 pl-2">
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Ponto
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Relatórios
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div className="font-semibold text-gray-800 mb-1">30 dias anteriores</div>
        <ul className="space-y-1 pl-2">
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Ponto
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Relatórios
            </a>
          </li>
        </ul>
      </div>

      <div>
        <div className="font-semibold text-gray-800 mb-1">Janeiro</div>
        <ul className="space-y-1 pl-2">
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Folha de Pagamento
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Geração de relatórios
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Cobertura do plano de saúde
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" className="text-primary-lg hover:underline">
              Comprovante de pagamento
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
